import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import { dynamicHeadlines } from "../../../utils/url-utils";
import Hero from "../../../components/Hero/hero";
import {
  retailLpHero,
  retailLpWhiteGlove,
  retailLpLargeFeatures,
  retailLpCTA,
  retailLpTestimonials,
  retailLpTrusted,
} from "../../../data/landing/retail-lp-data";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";

// const NoVisualsCustom = loadable(() =>
//   import("../../../components/NoVisuals/NoVisualsCustom")
// );

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);

const whiteGloveImport = "retailLp-whiteglove.png";

const retailLp = () => {
  return (
    <Layout isLanding phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="Retail | Website and Payments Software | SpotOn"
        description="Save time and boost sales with SpotOn Retail, the all-in-one point-of-sale solution designed to let you do it all."
        image={`https://spoton.com/${heroBg}`}
      />
      <Hero
        sectionData={retailLpHero}
        heroBg="retailLp-decor-hero.png"
        dynamicH1={dynamicHeadlines()}
      />
      <TrustedNumbers numbersArray={retailLpTrusted} />
      <section style={{ marginTop: "40px" }}>
        <TestmonialReviews sectionData={retailLpTestimonials} />
      </section>
      <PartnerLogos />
      <LargeFeatures
        sectionData={retailLpLargeFeatures}
        style={{ marginTop: "128px !important" }}
      />
      <WhiteGlove
        sectionData={retailLpWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeCta sectionData={retailLpCTA} />
    </Layout>
  );
};

export default retailLp;
